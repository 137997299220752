import React from "react";

class DetailIconComp extends React.Component {
  render() {
    return (
      <div className="details-with-icon">
        <img src={this.props.image} alt="detail-icon" />
        <h4>{this.props.text}</h4>
      </div>
    );
  }
}

export default DetailIconComp;
