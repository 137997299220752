import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import FactoringCalculator from "../../components/App/FactoringCalculator";
import "react-rangeslider/lib/index.css";
import PageBanner from "../../components/Common/PageBanner";
import QuoteCard from "../../components/Common/QuoteCard";
import heroImage from "../../assets/images/page-hero.jpg";
import cash from "../../assets/images/financing-solutions/cash.svg";
import finance from "../../assets/images/financing-solutions/finance.svg";
import easy from "../../assets/images/financing-solutions/easy.svg";
import approval from "../../assets/images/financing-solutions/approval.svg";
import eye from "../../assets/images/financing-solutions/eye.svg";
import platform from "../../assets/images/financing-solutions/platform.svg";
import factoring from "../../assets/images/financing-solutions/reverse-factoring.svg";
import platformDoc from "../../documents/SEA-Easy-Capital-Platform.pdf";
import CardInfo from "../../components/Common/CardInfo";
import DetailIconComp from "../../components/Common/DetailIconComp";
import whatisDoc from "../../documents/what-is-document.pdf";
import { Link } from "gatsby";

class InvoiceFinancing extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar />
        <div className="page">
          <PageBanner
            pageTitle="Invoice Financing"
            pageMainTitle="Convert your accounts receivables into cash today"
            pageDescription="We know it is tough to build and grow your business when you are waiting for your customers to pay. By buying your invoices, SEA Easy Capital helps you get paid right away."
            buttonText="Estimate My Fees"
            image={heroImage}
            scrollDestination=".fee-calculator-section"
          ></PageBanner>

          {/* fee calculator section  */}
          <FactoringCalculator />

          {/* why choose SEA section */}
          <section className="why-choose-sea">
            <div className="container">
              <div className="section-title">
                <span className="sub-title">
                  Why we're the best choice for you
                </span>
                <h2>Why Choose SEA Easy Capital</h2>
              </div>

              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <DetailIconComp
                    text="Access cash up to $500,000"
                    image={cash}
                  />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <DetailIconComp
                    text="Finance only those invoices you want"
                    image={finance}
                  />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <DetailIconComp text="Easy to apply" image={easy} />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <DetailIconComp
                    text="Approval within 24-48 hours"
                    image={approval}
                  />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <DetailIconComp text="No hidden fees or costs" image={eye} />
                </div>
                <div className="col-sm-12 text-center text-sm-left mt-5 mt-sm-0">
                  <p>
                    To get started with Invoice Financing or for more info, get
                    in touch with us today!
                  </p>
                  <Link to="/contact" className="default-btn no-icon">
                    Contact Us
                    <span />
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* What is factoring  */}
          <section className="what-is-factoring">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <CardInfo
                    image={factoring}
                    title="What is Invoice Financing?"
                    buttonText="Learn More"
                    linkDestination={whatisDoc}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <CardInfo
                    image={platform}
                    title="How our Platform works"
                    buttonText="Learn More"
                    linkDestination={platformDoc}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* quote section  */}
          {/* <section className="quote-section">
            <div className="container">
              <QuoteCard
                quote="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
                personname="John Doe"
                company="ABC Corp."
              />
            </div>
          </section> */}
        </div>
        <Footer />
      </Layout>
    );
  }
}

export default InvoiceFinancing;
