import React from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ReactTooltip from "react-tooltip";

class Chart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      InvoiceAmount: 0,
      CreditPeriod: 0,
      rightAwayAmount: 0,
      invoicePercentage: 0,
      interestRate: 0,
      fees: 0,
      balanceAmount: 0,
    };
  }

  handleOnChangeInvoiceAmount = (value) => {
    this.setState({
      InvoiceAmount: value,
    });
    this.calculation();
  };

  handleOnChangeCreditPeriod = (value) => {
    this.setState({
      CreditPeriod: value,
    });
    this.calculation();
  };
  handleOnChangeInvoicePercentage = (value) => {
    this.setState({
      invoicePercentage: value,
    });
    this.calculation();
  };
  handleOnChangeInterest = (value) => {
    this.setState({
      interestRate: value,
    });
    this.calculation();
  };

  calculation = () => {
    let ia = this.state.InvoiceAmount;
    let cp = this.state.CreditPeriod;
    let ip = this.state.invoicePercentage;
    let ir = this.state.interestRate;

    this.setState({
      fees: (ia * ip * (ir / 365) * cp).toFixed(2),
    });

    this.setState({
      rightAwayAmount: (ia * ip - this.state.fees).toFixed(2),
    });

    this.setState({
      balanceAmount: (
        ia -
        this.state.rightAwayAmount -
        this.state.fees
      ).toFixed(2),
    });

    // this.state.fees = (ia * ip * (ir / 365) * cp).toFixed(2);

    // this.state.rightAwayAmount = (ia * ip - this.state.fees).toFixed(2);

    // this.state.balanceAmount = (ia - this.state.rightAwayAmount).toFixed(2);
  };

  render() {
    let {
      InvoiceAmount,
      CreditPeriod,
      rightAwayAmount,
      balanceAmount,
      fees,
      invoicePercentage,
      interestRate,
    } = this.state;

    return (
      <section className="fee-calculator-section">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Estimate My Fees</span>
            <h2>Invoice Financing Fee Calculator</h2>
            <p>
              Wondering how much you can get for an invoice? Move the slider to
              estimate your fees for the amount you can get right away.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              {/* invoice amount  */}
              <h4 className="slider-heading">
                Invoice Amount
                <label>
                  <sup>$</sup>
                  {InvoiceAmount}
                </label>
              </h4>
              <Slider
                min={0}
                max={625000}
                step={1000}
                value={InvoiceAmount}
                tooltip={false}
                onChange={this.handleOnChangeInvoiceAmount}
              />

              {/* credit period  */}
              <h4 className="slider-heading slider-two">
                Credit Period (no. of days)
                <label>
                  {CreditPeriod} <sup>Days</sup>
                </label>
              </h4>
              <Slider
                min={15}
                max={120}
                step={5}
                value={CreditPeriod}
                tooltip={false}
                onChange={this.handleOnChangeCreditPeriod}
              />

              {/* percentage of invoice  */}
              <h4 className="slider-heading slider-two">
                % of Invoice you want financed upfront
                <label>
                  {(invoicePercentage * 100).toFixed(0)} <sup>%</sup>
                </label>
              </h4>
              <Slider
                min={0.8}
                max={0.95}
                step={0.05}
                value={invoicePercentage}
                tooltip={false}
                onChange={this.handleOnChangeInvoicePercentage}
              />

              {/* desired interest rate  */}
              <h4 className="slider-heading slider-two">
                Interest Rate
                <label>
                  {(interestRate * 100).toFixed(1)} <sup>%</sup>
                </label>
              </h4>
              <Slider
                min={0.02}
                max={0.15}
                step={0.005}
                value={interestRate}
                tooltip={false}
                onChange={this.handleOnChangeInterest}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="cal-value-card">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      <span>Amount you receive right away</span>
                      <sup>$</sup>
                      {rightAwayAmount}
                    </h4>
                  </div>
                  <div className="col-6">
                    <h5>
                      <span>Balance received after invoice is paid</span>
                      <sup>$</sup>
                      {balanceAmount}
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5 className="fees">
                      <span data-tip data-for="fee">
                        Fees <br />
                        (applied/ you must pay)<span className="info-i">i</span>
                      </span>
                      <ReactTooltip id="fee" place="top" effect="solid">
                        This is the total cost, comprising of the interest and
                        fees payable
                      </ReactTooltip>
                      <sup>$</sup>
                      {fees}
                    </h5>
                  </div>
                  <div className="col-12">
                    <p className="disclaimer">
                      DISCLAIMER: The figures generated by this calculator are
                      intended as a guide only, based on information that you
                      provide. This shall not, and is not intended to,
                      constitute a commitment that rates offered to you will
                      necessarily be better than those generated by the
                      calculator, and, in particular, is not a commitment to
                      provide any financing. Rates will be determined according
                      to, among other things, our analysis of your risk profile.
                      Any financing is subject to such terms and conditions,
                      disclaimers and lending criteria as may be set by SEA Easy
                      Capital Pte Ltd from time to time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Chart;
